import Alpine from "alpinejs";
import "focus-visible";
import htmx from "htmx.org";

import "./modules/nav";

window.Alpine = Alpine;
window.htmx = htmx;

document.addEventListener("htmx:configRequest", (evt) => {
	evt.detail.headers = [];
});

Alpine.start();

document.addEventListener("DOMContentLoaded", function () {
	const observerOptions = {
		threshold: 0.8,
	};
	const observer = new IntersectionObserver((entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.remove("opacity-0");
				entry.target.classList.add("opacity-100");
				observer.unobserve(entry.target);
			}
		});
	}, observerOptions);
	document.querySelectorAll(".transition-opacity").forEach((el) => {
		observer.observe(el);
	});
});
